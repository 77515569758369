/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */


export enum ClinicType {
    Veterinary = 1,
    Human = 2,
}


export enum AnimalSizes {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export enum ImageFileType {
    Jpg = 'Jpg',
    Dcm = 'Dcm',
}

export enum Species {
    Canine = 'Canine',
    Feline = 'Feline',
    Equine = 'Equine',
}

export enum EntityStatus {
    Inactive = 'Inactive',
    Active = 'Active',
}

export enum Genders {
    Male = 'Male',
    Female = 'Female',
}

export interface IModelBase {
    id: number;
}

export interface IClinicModel extends IModelBase {
    name: string;
    address: string;
    createdOn: Date;
    phone?: string;
    email?: string;
    aeTitle?: string;
    clinicType: number;
    tenantId?: string;
    stripeCustomerId?: string;
    usageBasedSubscriptionId?: string;
    subscription?: ISubscriptionModel;
}

export interface ISubscriptionModel extends IModelBase {
    clinicId: number;
    createdOn: string;
    updatedOn: string;
    stripeCustomerId: string;
    status: string;
    stripeSubscriptionId: string;
    managedByTenant: boolean;
    trialEndDate: string;
}

export interface IDicomServerModel extends IModelBase {
    serverName: string;
    aeTitle: string;
    host: string;
    port: number;
    description: string;
    createdOn?: Date;
    clinicId: number;
    tls: boolean;
}

export interface IImageModel extends IModelBase {
    studyId: number;
    bodyPart: string;
    fileName: string;
    sopInstanceUID: string;
    seriesInstanceUID: string;
    aquisitionDate?: Date;
    clinicId: number;
    modality?: string;
    transferSyntax?: string;
    tags?: string;
    sourceSize?: number;
    storageSize?: number;
    storageFormat?: string;
    createdDate?: Date;
}

export interface IPatientModel extends IModelBase {
    name: string;
    createdDate?: Date;
    clinicId: number;
    accessionCode: string;
    patientId: string;
    status?: EntityStatus;
    responsiblePerson: string;
    responsiblePersonRole: string;
}

export interface StudyListResponse extends IModelBase {
    patientId: number;
    responsiblePerson: string;
    responsiblePersonRole: string;
    patientName: string;
    studyDateTime?: Date;
    imageCount: number;
    previewImages: StudyListPreviewImage[];
}

export interface StudyListPreviewImage extends IModelBase {}

export interface IStudieModel extends IModelBase {
    studyDateTime?: Date;
    patientId: number;
    patient?: IPatientModel;
    clinicId: number;
    studyInstanceUid: string;
    sopInstanceUid: string;
    relativePath: string;
    status?: EntityStatus;
    images: IImageModel[];
    createdDate?: Date;
}

export interface IStudyViewerStateModel extends IModelBase {
    studyId: number;
    createdOn?: Date;
    state: string;
}

export interface IUserModel extends IModelBase {
    username: string;
    normalizedUsername: string;
    email: string;
    normalizedEmail: string;
    emailConfirmed: boolean;
    passwordHash: string;
    firstName: string;
    lastName: string;
    createdOn?: Date;
    clinicId: number;
    isRoot: boolean;
    lastSignIn?: Date;
    securityStamp: string;
    concurrencyStamp: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEnd?: Date;
    lockoutEnabled: boolean;
    accessFailedCount: number;
    tenantId: string;
    clinic?: IClinicModel;
}

export interface IDicomSendRequest {
    imageIdList: number[];
    dicomServerId: number;
}

export interface IEmailSendRequest {
    imageIds: number[];
    toAddresses: string[];
    subject: string;
    body?: string;
}

export interface IAnnotation {
    bodyPart: string;
    bodyPartView?: string;
    animalSize?: AnimalSizes;
}

export interface IDownloadImagesRequest {
    imageIds: number[];
    type: ImageFileType;
}

export interface ISubscription {
    id: number
    stripeCustomerId: string
    clinicId: number
    createdOn: string
    updatedOn: string
    status: string
    stripePriceId: string
    interval: string
    amount: number
    currency: string
    tier: "standard" | "advanced"
    dealerName: string | null
    isExternal: boolean
}

export interface IEditClinicRequest {
    clinicId: number,
    clinicName: string
    address: string
    phone: string
    email: string
}

export interface IPagedResponse<T extends {}> {
    page: number;
    pageSize: number;
    totalRecords: number;
    records: T[];
}

export interface IPacsInfoModel {
    pacsReceiverPort: number
    pacsReceiverHost: string
    aeTitle: string
}
